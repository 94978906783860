<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="用户id" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="消息ID :，guild_messagenotice_id_id" prop="messageId" >-->
<!--        <a-input v-model="form.messageId" placeholder="请输入消息ID :，guild_messagenotice_id_id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="消息类型" prop="type" >
        <a-select placeholder="请选择消息类型" v-model="form.type">
          <a-select-option :value="1">平台公告</a-select-option>
          <a-select-option :value="2">系统通知</a-select-option>
<!--          <a-select-option v-for="(d, index) in typeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="互动消息分类" prop="detailType" >-->
<!--        <a-select placeholder="请选择互动消息分类" v-model="form.detailType">-->
<!--          <a-select-option v-for="(d, index) in detailTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="订单id" prop="orderId" >-->
<!--        <a-input v-model="form.orderId" placeholder="请输入订单id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="动态id" prop="dynamicId" >-->
<!--        <a-input v-model="form.dynamicId" placeholder="请输入动态id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="房间id" prop="roomId" >-->
<!--        <a-input v-model="form.roomId" placeholder="请输入房间id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="是否已读" prop="readFlag" >-->
<!--        <a-select placeholder="请选择是否已读" v-model="form.readFlag">-->
<!--          <a-select-option v-for="(d, index) in readFlagOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="消息标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入消息标题" />
      </a-form-model-item>
      <a-form-model-item label="二级标题" prop="title2" >
        <a-input v-model="form.title2" placeholder="请输入消息二级标题" />
      </a-form-model-item>
      <a-form-model-item label="展示封面" prop="image" >
        <file-upload2 type="video" :count="1" v-model="form" fieldName="image"></file-upload2>
<!--        <a-upload-->
<!--          listType="picture-card"-->
<!--          class="avatar-uploader"-->
<!--          :showUploadList="false"-->
<!--          :customRequest='fileUploadRequest'-->
<!--          :beforeUpload="beforeUpload"-->
<!--          @change="handleChange"-->
<!--        >-->
<!--          <img v-if="form.image" :src="form.image" alt="展示封面" style="height:104px;max-width:300px"/>-->
<!--          <div v-else>-->
<!--            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>-->
<!--            <div class="ant-upload-text">上传</div>-->
<!--          </div>-->
<!--        </a-upload>-->
      </a-form-model-item>

      <a-form-model-item label="消息内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="角色" prop="userRole" >
        <a-select placeholder="请选择角色" v-model="form.userRole">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">创业者</a-select-option>
          <a-select-option :value="2">投资人</a-select-option>
          <a-select-option :value="3">BP顾问</a-select-option>
          <a-select-option :value="4">分析师</a-select-option>
          <!--          <a-select-option v-for="(d, index) in userRoleOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMessage, addMessage, updateMessage } from '@/api/biz/message'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    },
    detailTypeOptions: {
      type: Array,
      required: true
    },
    readFlagOptions: {
      type: Array,
      required: true
    },
    userRoleOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        messageId: null,

        type: null,

        detailType: null,

        orderId: null,

        dynamicId: null,

        roomId: null,

        readFlag: null,

        title: null,

        title2: null,

        content: null,

        createTime: null,

        remark: null,

        userRole: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        messageId: null,
        type: null,
        detailType: null,
        orderId: null,
        dynamicId: null,
        roomId: null,
        readFlag: null,
        title: null,
        title2: null,
        content: null,
        createTime: null,
        remark: null,
        userRole: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMessage({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.form.type) {
            this.$message.error("请选择消息类型")
            return false;
          } else if (!this.form.title) {
            this.$message.error("请输入标题")
            return false;
          } else if (!this.form.content) {
            this.$message.error("请输入内容")
            return false;
          }

          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMessage(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMessage(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }

        } else {
          return false
        }
      })
    }
  }
}
</script>
