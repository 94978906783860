import request from '@/utils/request'


// 查询用户消息记录列表
export function listMessage(query) {
  return request({
    url: '/biz/userMessage/list',
    method: 'get',
    params: query
  })
}

// 查询用户消息记录分页
export function pageMessage(query) {
  return request({
    url: '/biz/userMessage/page',
    method: 'get',
    params: query
  })
}

// 查询用户消息记录详细
export function getMessage(data) {
  return request({
    url: '/biz/userMessage/detail',
    method: 'get',
    params: data
  })
}

// 新增用户消息记录
export function addMessage(data) {
  return request({
    url: '/biz/userMessage/add',
    method: 'post',
    data: data
  })
}

// 修改用户消息记录
export function updateMessage(data) {
  return request({
    url: '/biz/userMessage/edit',
    method: 'post',
    data: data
  })
}

// 删除用户消息记录
export function delMessage(data) {
  return request({
    url: '/biz/userMessage/delete',
    method: 'post',
    data: data
  })
}
